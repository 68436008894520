import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Constants } from "../../../../constants/constants";
import { LinodeAccountsService } from "../linode-accounts.service";
import { TitleService } from "../../../../services/title.service";
import { take } from "rxjs/operators";
import { LinodeAccount } from "@zixi/models";

@Component({
    selector: "app-linode-account-form",
    templateUrl: "./linode-account-form.component.html"
})
export class LinodeAccountFormComponent implements OnInit, OnDestroy {
    linodeAccount: LinodeAccount;
    linodeAccountNames: string[];
    accountId: number;
    action: string;

    submitted = false;
    minLength = 2;
    saving = false;
    isEdit: boolean;
    loading = true;
    constants = Constants;

    private linodeAccountsSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ts: LinodeAccountsService,
        private titleService: TitleService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.accountId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.accountId != null) {
                this.ts.refreshLinodeAccounts();

                this.ts.linodeAccounts.pipe(take(1)).subscribe(linodeAccounts => {
                    this.linodeAccount = Object.assign(
                        {},
                        linodeAccounts.find(t => t.id === this.accountId)
                    );
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.prepForm();
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            if (this.action === "edit") this.isEdit = true;
        }

        if (!this.linodeAccount && !this.isEdit) {
            this.linodeAccount = new LinodeAccount({});
            this.linodeAccount.name = "";
        }

        // Set Title
        this.titleService.setTitle("LINODE_ACCOUNT", this.action, this.linodeAccount);
    }

    ngOnInit() {
        this.ts.refreshLinodeAccounts();

        this.linodeAccountsSubscription = this.ts.linodeAccounts.subscribe((linodeAccounts: LinodeAccount[]) => {
            if (this.isEdit)
                this.linodeAccountNames = linodeAccounts.filter(t => t.id !== this.accountId).map(t => t.name);
            else this.linodeAccountNames = linodeAccounts.map(t => t.name);
        });
    }

    ngOnDestroy() {
        if (this.linodeAccountsSubscription) this.linodeAccountsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.linodeAccount.name,
            api_key:
                !this.isEdit || (this.linodeAccount.api_key && this.linodeAccount.api_key.length > 0)
                    ? this.linodeAccount.api_key
                    : undefined
        };

        const result = this.isEdit
            ? await this.ts.updateLinodeAccount({ params: { id: this.accountId }, body: model })
            : await this.ts.addLinodeAccount({ body: model });

        this.saving = false;

        if (result !== false) {
            this.router.navigate([Constants.urls.configuration.linode]);
        }
    }

    cancel() {
        this.router.navigate([Constants.urls.configuration.linode]);
    }
}
