<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 title="{{ 'LINODE_ACCOUNTS' | translate }}">{{ "LINODE_ACCOUNTS" | translate }}</h1>
    </div>
</div>
<!-- Tabs -->
<div class="tab-container with-router">
    <div class="tabset">
        <ul ngbNav #nav="ngbNav" class="justify-content-start nav nav-tabs">
            <li ngbNavItem id="linode-accounts-list-tab">
                <a ngbNavLink class="nav-link" title="{{ 'ACCOUNTS' | translate }}">{{ "ACCOUNTS" | translate }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
            <li ngbNavItem id="linode-accounts-doc-tab">
                <a ngbNavLink class="nav-link" title="{{ 'DOCUMENTATION' | translate }}">{{ "DOCUMENTATION" | translate }}</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <div class="row"></div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
