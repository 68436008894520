import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { LinodeAccountsComponent } from "./linode-accounts.component";
import { LinodeAccountFormComponent } from "./linode-account-form/linode-account-form.component";
import { LinodeAccountListComponent } from "./linode-account-list/linode-account-list.component";

// Routes
import { LinodeAccountsRouting } from "./linode-accounts.routing";

@NgModule({
    imports: [CommonModule, FormsModule, NgSelectModule, NgbModule, FontAwesome, SharedModule, LinodeAccountsRouting],
    declarations: [LinodeAccountsComponent, LinodeAccountFormComponent, LinodeAccountListComponent]
})
export class LinodeAccountsModule {}
