import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { LinodeAccountsService } from "../linode-accounts.service";
import { LinodeAccount } from "@zixi/models";

@Injectable({
    providedIn: "root"
})
export class LinodeAccountListResolverService implements Resolve<LinodeAccount[]> {
    constructor(private tps: LinodeAccountsService) {}

    resolve(): Observable<LinodeAccount[]> | Observable<never> {
        return new Observable((observe: Subscriber<LinodeAccount[]>) => {
            this.tps.refreshLinodeAccounts().subscribe((linodeAccounts: LinodeAccount[]) => {
                observe.next(linodeAccounts);
                observe.complete();
            });
        });
    }
}
