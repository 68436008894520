import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
    selector: "app-linode-accounts",
    templateUrl: "./linode-accounts.component.html"
})
export class LinodeAccountsComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method,@typescript-eslint/no-empty-function
    ngOnInit() {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method,@typescript-eslint/no-empty-function
    ngOnDestroy() {}
}
